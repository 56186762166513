<template>
  <div ref="floating-task-header" class="floating-task-header box box-default box-solid">
    <div class="settlement-header">
      <div style="display: flex; flex-direction: row; margin: 1rem 0">
        <div>
          <label>Autor:</label>
          <p
            class="settlement-header-author"
          >
            {{ data.createdByLabel }}
          </p>
        </div>
        <div>
          <label>Utworzone:</label>
          <p class="settlement-header-createdAt">
            {{ data.createdAt ? formatDateTimestamp(data.createdAt.timestamp) : 'b.d'}}
          </p>
        </div>
        <div>
          <label>Okres rozliczenia:</label>
          <p class="settlement-header-date-interval">
            {{ data.beginAt ? formatDateWithoutTime(data.beginAt.timestamp) + ' - ' + formatDateWithoutTime(data.endAt.timestamp) : 'b.d'}}
          </p>
        </div>
        <div>
          <label>Wysłane:</label>
          <p class="settlement-header-sentAt">
            {{  data.sentAt ? formatDateTimestamp(data.sentAt.timestamp) : 'b.d' }}
          </p>
        </div>
        <div>
          <label>Zaakceptowanie:</label>
          <p class="settlement-header-acceptedAt">
            {{data.acceptedAt ? formatDateTimestamp(data.acceptedAt.timestamp) : 'b.d' }}
          </p>
        </div>
        <div>
          <label>Numer faktury:</label>
          <p class="settlement-header-invoiceNumber">
            {{ data.invoiceNumber ? data.invoiceNumber : 'b.d'}}
          </p>
        </div>
        <div>
          <label>Data faktury:</label>
          <p class="settlement-header-invoiceDate">
            {{data.invoiceDate ? formatDateTimestamp(data.invoiceDate.timestamp) : 'b.d'}}
          </p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'SettlementHeader',
  props: {
    data: {type: Object, default: () => ({})}
  },
  data () {
    return {
      taskHeader: null
    }
  },
  mounted () {
    this.taskHeader = this.$refs['floating-task-header']
    this.$emit('task-header-shadow', {hasShadow: false, height: this.taskHeader.getBoundingClientRect().height})
  },
  methods: {
    formatDateTimestamp (unixTimestamp) {
      const date = new Date(unixTimestamp * 1000)
      return date.toLocaleDateString('sv') + ' ' + date.toLocaleTimeString('pl-PL').substr(0, 5)
    },
    formatDateWithoutTime (unixTimestamp) {
      const date = new Date(unixTimestamp * 1000)
      return date.toLocaleDateString('sv')
    }
  }
}
</script>

<style scoped>
.settlement-header > div > div {
  text-align: center;
  margin: 0 10px;
  width: 20%;
}
.floating-task-header {
  margin-bottom: 0px;
  z-index: 1000;
}
</style>
